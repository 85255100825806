<template>
    <div class="card-align">
        <div>
            <div class="card">
                <div class="card-body">
                    <div class="margin-btm meetup-header d-flex align-items-center">
                        <div class="my-auto text-icon-align ">
                            <span class=" pointer algin-right mb-1">
                                <span @click="editViewDetails()" class="icon-box-new-edit">
                                    <feather-icon class=" rotate" icon="EditIcon" size="20" />
                                </span>
                                <span @click="updateVulnerabilities()" v-if="editableValue">
                                    <button class="btn pointer btn-primary btn-sm margin-left" :disabled="!this.$store.state.auth.permissions.includes('vulnerability-update')">update</button>
                                </span>
                            </span>
                            <label v-if="editableValue" style="margin-top:20px;">Name</label>
                            <div :class="[{'bordereditable': editableValue}, 'font-weiht']"
                                :contenteditable="editableValue" id="assetname">
                                <h4>{{detailData.name}}</h4>
                            </div>

                            <div v-if="editableValue " class="mt-1">
                                <label>Asset Categories</label>
                                <vue-autosuggest class="width-400px" :suggestions="newUserOptionEdit" :limit="10"
                                    v-model="selectedAssetEditName" id="autosuggest__input" :input-props="tagInputProps"
                                    @input="getSearchEdit" @selected="onTagSelected"
                                    :get-suggestion-value="getTagSuggestionValue">
                                    <template slot-scope="{ suggestion }" style="display: flex; align-items: center">
                                        <span style="{ display: 'flex', color: 'navyblue'}">{{
                                            suggestion.item

                                            }}</span>
                                    </template>
                                </vue-autosuggest>
                                <div>
                                    <ul class="ul-auto-suggest">
                                        <li v-for="data in DetailedselectedAsset">
                                            <span>
                                                {{data.tag_title}}
                                            </span>
                                            <feather-icon @click="closeData(data.tag_id)"
                                                class="text-primary pointer rotate" icon="XIcon" size="15" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="editableValue" class="width-400px">
                                <label>Vulnerability Value</label>
                                <v-select v-model="selectedData" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title" :options="option" />
                            </div>
                            <label v-if="editableValue">Description</label>
                            <div id="assetdescription" :class="[{'textarea-editable': editableValue}]"
                                :contenteditable="editableValue">
                                <p>{{detailData.description}}</p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card width-25">
            <div class="card-body">
                <div class="flex">
                    <div class="mr-50">
                        <p class="card-text">
                        <h5 class="mb-75 font-weiht">
                            Asset Categories
                        </h5>
                        <div v-for="value in DetailedselectedAsset">
                            <feather-icon class="mr-right text-primary rotate" icon="GridIcon" size="15" />
                            {{value.tag_title}}
                        </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, BSidebar, VBToggle, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    detailCurrentData: {},
    detailID:'',
    currentData: [],
    detailData:[],
    DetailedselectedAsset:[],
    assets:[],
    currentAssetDetail:[],
    hideSideBar: false,
    editVulnerabilitiesData: false,
    vulnerabilitiesName: '',
    vulnerabilitiesDescription: '',
    selectedAsset: [],
    editVulnerabilitiesID: '', 
    allVulnerabilities: [],
    editableValue: false,
    newUserOptionEdit: [],
    selectedAssetEditName: '',
        tagInputProps: {
        class: "form-control",
        placeholder: "Add new",
    },
    selectedAssetEditData:[],
    autoData: [],
    selectedData: [],
    dir: 'ltr',
    option: [
        { title: 'Very Low', value: 1 },
        { title: 'Low', value: 2 },
        { title: 'Medium', value: 3 },
        { title: 'High', value: 4 },
        { title: 'Very High', value: 5 }
    ],
        
  }),
components: {
vSelect,
BFormTextarea,
BSidebar,
VueAutosuggest
},
props: {
   
},
  directives: {
    'b-toggle': VBToggle,
  },
async mounted(){
    this.detailID = this.$route.params.id
   this.assets = await this.getAssets()
    this.getVulnerabilities();
    this.getVulnerability();
},
methods: {
    getSearchEdit(name) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/ast_category/search?name=${name}`,
        };
        this.$http(options)
            .then((res) => {
                this.autoData = res.data.data
                for (let [key, value] of Object.entries(this.autoData)) {
                    let a = {
                        data: [value.name]
                    };
                    this.newUserOptionEdit.push(a);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
    onTagSelected(val) {
      console.log("val", val);

      if (val) {
        const selectedVal = val.item;
          this.assets.filter(ele => {
              if (ele.name == selectedVal) {
                  this.DetailedselectedAsset.push({
                      tag_id: ele._id,
                      tag_title: ele.name
                  })
                    this.selectedAssetEditName= "";
              }
          })

      }

    },
    getTagSuggestionValue(suggestion) {
        return suggestion.item;

    },
    editViewDetails() {
        this.editableValue = true
    },
    getVulnerabilitiesSearchData() {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities?asset_category_id=${this.detailID}`,
        };
        this.$http(options)
            .then((res) => {
                this.allVulnerabilities = res.data.data.data
            })
            .catch((err) => {
                console.log(err);
            });
    },
    closeData(data) {
        this.DetailedselectedAsset = this.DetailedselectedAsset.filter(ele => ele.tag_id != data)

    },
    updateVulnerabilities() {
        const data1 = this.DetailedselectedAsset
        const val = []
        data1.forEach(ele => {
            val.push(ele.tag_id)
        });
        this.vulnerabilitiesName = document.getElementById("assetname").innerText;
        this.vulnerabilitiesDescription = document.getElementById("assetdescription").innerText;

        let data = {
            name: this.vulnerabilitiesName,
            description: this.vulnerabilitiesDescription,
            asset_category_id: val,
            vulnerability_value: this.selectedData.value
        };
        const options = {
            method: "PUT",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities/${this.detailID}`,
        };
        this.$http(options)
            .then((res) => {
                this.editableValue = false
                this.vulnerabilitiesName = ''
                this.vulnerabilitiesDescription = ''
                this.selectedAsset = []

                this.editVulnerabilitiesData = false,
                    this.hideSideBar = false
                    if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vulnerability Updated !",
                icon: "EditIcon",
                variant: "success",
              },
            });
            
          }
            })
            .catch((err) => {
                console.log(err);
            });

    },
    getVulnerability() {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities/${this.detailID}`,
        };
        this.$http(options)
            .then((res) => {
             
            })
            .catch((err) => {
                console.log(err);
            });
        
    },
     getVulnerabilities() {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
        };
        this.$http(options)
            .then((res) => {
              this.currentData = res.data.data.data
              const value =   this.currentData.filter(ele=> ele._id == this.detailID)
              this.detailData = value[0]
              if(this.detailData.vulnerability_value) {
                  this.option.forEach(ele => {
                      if (ele.value == this.detailData.vulnerability_value) {
                          this.selectedData.push(ele)
                      }
                  })
              }
              this.assets.forEach(ele => {
                    this.detailData.asset_category_id.filter(el=>{
                        if(ele._id == el){
                            this.DetailedselectedAsset.push({
                                tag_id: ele._id,
                                tag_title: ele.name
                            })
                        }
                    })
                    
                });
            })
            .catch((err) => {
                console.log(err);
            });
        
    },
    getAssets() {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories`,
        };
       return this.$http(options)
            .then((res) => {
               return res.data.data.data
            })
            .catch((err) => {
                console.log(err);
            });

    },
}

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.flex-inline{
    display: inline-flex;
    margin-right: 5px;
}
  .ul-auto-suggest {
    list-style: none;
    padding: 0;
    display: inline-flex;
  }
.ul-auto-suggest li {
    padding: 5px;
    border: 1px solid #6610f2;
    border-radius: 5px;
    margin-top: 8px;
    margin-right: 8px;
}
.mr-right {
    margin-right: 5px;
}
.mr-50{
    margin-right: 100px !important;
}
.margin-left {
    margin-left: 15px
}
.flex {
    display: flex;
}
.bordereditable {
    border: 1px solid #d1cdcd;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    width: 100%;
    // height: 35px;
}
.width-400px {
    width: 100%;
}
.text-icon-align {
    float:right;
    width:100%
}
.textarea-editable {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    border: 1px solid #d1cdcd;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    height: 65px;
    overflow: auto;
    padding: 2px;
    resize: both;
    width: 100%;
    border-radius: 5px;
    outline: none
}
.timeline-item-point-2 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: yellow;
    display: inline-flex;
    margin-right: 5px;
}
.font-weiht {
    font-weight: 700;
}

.padding-20 {
    padding: 20px;
}
.icon-box-new-edit {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
} 
.text-primary-black {
  color: #ffff;
}
.algin-right {
    float: right;
    align-items: center;
    display: flex
}
</style>
